
/**
 * Restrict input characters
 * http://www.qodo.co.uk/blog/javascript-restrict-keyboard-character-input/
 */
function restrictCharacters(myfield, e, restrictionType) {
    if (!e) var e = window.event;
    if (e.keyCode) code = e.keyCode;
    else if (e.which) code = e.which;
    var character = String.fromCharCode(code);
    // if they pressed esc... remove focus from field...
    if (code==27) { this.blur(); return false; }
    // ignore if they are press other keys
    // strange because code: 39 is the down key AND ' key...
    // and DEL also equals .
    if (!e.ctrlKey && code!=9 && code!=8 && code!=36 && code!=37 && code!=38 && (code!=39 || (code==39 && character=="'")) && code!=40) {
        if (character.match(restrictionType)) {
            return true;
        } else {
            return false;
        }
    }
}

/**
 * Convert url to slug
 */
function slugify(str, special_char) {
    if (typeof(special_char)==='undefined') special_char = '-';
    str = str.toLowerCase();
    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, special_char) // collapse whitespace and replace by -
        .replace(/-+/g, special_char); // collapse .

    return str;
}

/**
 * Remove string from beginnig
 */
function removeFromHead(needle, haystack) {
    needle = needle.toLowerCase();
    var ls_haystack = haystack.toLowerCase();
    while (needle.length > 0) {
        if ( ls_haystack.indexOf(needle) == 0 ) {
            return haystack.substring(needle.length);
        } else {
            needle = needle.slice(0, -1);
        }
    }
    return haystack;
}

/**
 * Notification alert
 * @param title
 * @param message
 * @param type
 */
function notify(title, message, type) {
    $.growl({
        message: message,
        title: title
    },{
        type: type,
        allow_dismiss: true,
        animate: {
            enter: 'animated fadeInUp',
            exit: 'animated fadeOutDown'
        }
    });
}

/**
 * We'll register a HTTP interceptor to attach the "CSRF" header to each of
 * the outgoing requests issued by this application. The CSRF middleware
 * included with Laravel will automatically verify the header's value.
 */
$(function() {
    $.ajaxSetup({
        headers: {
            'X-CSRF-Token': Laravel.csrfToken
        }
    });
});

$(document).ready(function() {

    //delete
    $('.delete-link').click(function(e){
        e.preventDefault();
        var request_url = $(this).data('request-url');
        var redirect_url = $(this).data('redirect-url');
        swal({
            title: 'Are you sure?',
            text: 'You will not be able to undo this delete operation!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
        }).then(function(){
            $.ajax({
                url: request_url,
                type: 'DELETE',
                success: function (result) {
                    swal('Deleted!', 'The record has been deleted.', 'success').then(
                        function() {
                            window.location.replace(redirect_url);
                        }
                    );
                },
                error: function () {
                    swal('Error!', 'An error occurred while deleting.', 'error');
                }
            });
        });
    });

    //url input
    $("input[type='url']").keyup(function(e) {
        string = $(this).val();
        if(!(/^(http|https):\/\//i.test(string))){
            var protocol = 'http';
            var removed = removeFromHead("http", string);
            if ( removed.indexOf('s') == 0 ) {
                removed = removeFromHead("s://", removed);
                protocol += 's';
            } else {
                removed = removeFromHead("://", removed);
            }
            if (removed != '') {
                string = protocol + "://" + removed;
            }
        }
        $(this).val(string);
    });

    //check all boxes when select all clicked
    $('input[type="checkbox"][data-all]').on('change', function (e) {
        e.preventDefault();
        var select_all_name = $(this).data('all');
        var is_checked = $(this).prop('checked');
        $('input[type="checkbox"][data-check="'+select_all_name+'"]').each(function () {
            $(this).prop('checked', is_checked);
        });
    });

    //uncheck select all when one box unchecked
    $('input[type="checkbox"][data-check]').on('change', function (e) {
        e.preventDefault();
        var is_checked = $(this).prop('checked');
        if ( !is_checked ) {
            var select_all_name = $(this).data('check');
            var select_all = $('input[type="checkbox"][data-all="'+select_all_name+'"]');
            if ( select_all ) {
                select_all.prop('checked', false);
            }
        }
    });

});
